<template>
  <div>
    <c-tab
      ref="plantDocuDetail"
      type="horizon"
      :height="tabHeight"
      :tabItems.sync="tabItems"
      :dense="true"
      :inlineLabel="true"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="popupParam"
          :contentHeight="contentHeight"
          @changeStatus="changeStatus"
          @transInfo="transInfo"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'emergency-action-plan-docu-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopEmergencyPlanId: '',
        plantCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      editable: true,
      detailUrl: '',
      tabItems: [],
      addTabItems: [],
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },

  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'base', icon: 'foundation', label: '기본정보', component: () => import(`${'./emergencyActionPlanDocuTab.vue'}`) },
      ];
      this.addTabItems = [
        { name: 'traningScenario', icon: 'account_tree', label: '훈련시나리오', component: () => import(`${'./traningScenario.vue'}`) },
      ];
      if (this.popupParam.sopEmergencyPlanId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    transInfo(data) {
      this.$set(this.popupParam, 'sopEmergencyPlanId', data.sopEmergencyPlanId)
      this.$set(this.popupParam, 'plantCd', data.plantCd)
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
  }
};
</script>
